@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.side-bar-wrapper {
  width: 320px;
  padding: 16px;
  border-radius: 0px !important;
  box-sizing: border-box;
  background-color: $alabaster-color !important;

  .user-info-section {
    display: flex;
    align-items: center;
    margin: 12px 12px 24px 12px;
    .default-avatar {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      @include align-center;
      .avatar-text {
        color: $white-color;
      }
    }
    .user-info {
      margin: auto 20px;
    }
  }

  .menu-list {
    margin-bottom: 8px;
    .menu-item {
      height: 52px;
      display: flex;
      cursor: pointer;
      border-radius: 16px;
      align-items: center;
      text-decoration: none;
      box-sizing: border-box;
      padding: 16px 16px 16px 12px;
      .menu-icon {
        margin-inline-end: 12px;
      }
      .menu-name {
        flex: 1;
      }
      .menu-badge {
        color: $black-squeeze;
        padding: 4px 6px;
        border-radius: 4px;
        margin-inline-end: 4px;
      }
    }
    .active-menu-item {
      .menu-name {
        color: $white-color;
      }
      .menu-badge {
        background-color: $black-squeeze;
      }
    }
  }

  .login-alert-section {
    background: rgba(80, 62, 157, 0.1);
    width: 100%;
    border: 1px solid rgba(163, 163, 164, 0.16);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    padding: 12px 0;
    // height: 100%;
    // @include align-center;
    // flex-direction: column;
    // .login-alert-icon {
    //   margin-bottom: 32px;
    // }
    // .section-info-text {
    //   text-align: center;
    //   margin-bottom: 6px;
    // }
    .profile-icon-wrapper {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      align-items: center;
      background: #fff;
      display: flex;
      justify-content: center;
    }
    .login-content {
      width: 70%;
      .button-wrapper {
        width: 100px;
        padding: 8px 0;
      }
      .user-account-text {
        padding: 12px 0;
        line-height: 140%;
        color: #57606f;
      }
    }
  }
  .downloadApp-btn-wrapper {
    background-color: rgba(80, 62, 157, 0.1);
    height: 72px;
    width: 100%;
    border: 1px solid rgba(163, 163, 164, 0.16);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .download-btn {
      width: 120px;
      height: 45px;
    }
    .app-store {
      height: 38px;
    }
    .play-store {
      height: 45px;
    }
  }
  .no-auth {
    padding: 12px 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 768px) {
  .side-bar-wrapper {
    .user-info-section {
      margin: 12px 12px 24px 12px;
    }
  }
}
